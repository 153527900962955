<template>
	<div v-loading="loading" element-loading-text="数据加载中">
		<div class="form-body" style="padding-left: 60rem">
			<h4>基础信息</h4>
			<br />
			<el-form :model="FormData" :rules="rules" ref="ruleForm" label-width="100rem">
				<el-form-item label="选择考试" prop="exam_id">
					<!-- <el-select :popper-append-to-body="false" v-model="FormData.exam_id" placeholder="请选择" @change="examidChange" disabled>
						<el-option v-for="item in examArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select> -->
					<!-- <el-input v-model="FormData.exam_name" disabled></el-input> -->
					{{ FormData.exam_name }}
				</el-form-item>
				<el-form-item label="选择科目" prop="subject">
					<!-- <el-cascader
						v-model="FormData.subject"
						:options="subjectArr"
						placeholder="请选择"
						@change="subjectidChange"
						disabled
					></el-cascader> -->
					{{ FormData.subject_name }}
				</el-form-item>
				<el-form-item label="题目数量" prop="repnum">
					<!-- <el-input-number
						v-model="FormData.repnum"
						:min="min"
						:max="1000"
						style="width: 140rem !important"
						:step="1"
						step-strictly
						disabled
					></el-input-number> -->
					{{ FormData.repnum }}
					<span style="margin-left: 20rem; color: #b0b0b0">建议一题多问时,计为多个题目,方便数据分析,且最少1道题目</span>
				</el-form-item>
				<br />
				<h4 style="margin-bottom: 24rem">关联知识点</h4>
				<div class="knowledge" v-for="(item, index) in FormData.wiki" :key="index">
					<el-col :span="22" class="knowledge_box">
						<el-col :span="5">
							<el-form-item label="题号">
								<!-- <el-input
									disabled
									v-model="FormData.wiki[index].serialnum"
									placeholder="请输入题号"
									@blur="serialnumBlur(item, index)"
									@focus="serialnumFocused(item, index)"
								></el-input> -->
								{{ FormData.wiki[index].serialnum }}
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="分数">
								<!-- <el-input-number
									disabled
									v-model="FormData.wiki[index].score"
									:min="1"
									:max="99999"
									style="width: 140rem !important"
									:step="1"
									step-strictly
								></el-input-number> -->
								{{ FormData.wiki[index].score }}
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="涉及考点">
								<!-- <el-cascader
									disabled
									v-model="FormData.wiki[index].abilitys"
									:options="abilitysArr"
									:props="{ multiple: true }"
									:show-all-levels="false"
									:clearable="true"
									placeholder="请选择涉及考点"
									style="width: 10.55502183406114vw"
								>
								</el-cascader> -->
								<p v-for="(i, n) in FormData.wiki[index].ability_names">{{ i }}</p>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="设置关联题">
								<!-- <el-select disabled v-model="FormData.wiki[index].set_title" placeholder="请选择" @change="settitleChange(item, index)">
									<el-option v-for="item in titleArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
								</el-select> -->
								<div v-if="!!FormData.wiki[index].set_title">
									{{ titleArr[FormData.wiki[index].group_id - 1] ? titleArr[FormData.wiki[index].group_id - 1].label : null }}
								</div>
								<!-- {{ !!FormData.wiki[index].set_title ? titleArr[FormData.wiki[index].group_id - 1].label : '' }} -->
							</el-form-item>
						</el-col>
					</el-col>
					<!-- 关联知识点新增题目按钮 -->
					<el-col :span="2" class="add_knowledge">
						<!-- <el-button icon="el-icon-delete" circle @click="deleteKnowledge(item, index)"></el-button>
						<el-button icon="el-icon-plus" circle @click="addKnowledge" v-if="FormData.wiki.length - 1 == index"></el-button> -->
					</el-col>
				</div>
			</el-form>
		</div>
		<div class="footer-button">
			<!-- <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button> -->
			<el-button @click="() => this.$router.back()" type="primary">返回</el-button>
		</div>
	</div>
</template>

<script>
import { getExam, examSubject, abilityList, repository, putrepository } from './api'
export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  data() {
		return {
			hosting: '',

			// 题目最小数量
			min: 1,
			examArr: [], // 考试数据存放
			subjectArr: [], // 科目数据存放
			abilitysArr: [], // 涉及考点数据存放
			titleArr: [], // 关联题数据存放
			FormData: {
				// 表单数据存放
				exam_id: '', // 考试
				subject: '', // 科目
				repnum: 1, // 题目数量
				wiki: [
					{
						serialnum: '', //题号
						score: '', //	分数
						abilitys: '' //	知识点
					}
				]
			},
			loading: true,
			rules: {
				exam_id: [{ required: true, message: '请选择考试', trigger: 'change' }],
				subject: [{ required: true, message: '请选择考试科目', trigger: 'change' }],
				repnum: [{ required: true, message: '请输入题目数量', trigger: 'change' }]
			}
		}
	},

	methods: {
		// 获取考试科目
		examidChange(val) {
			// this.abilitysArr = []
			this.FormData.subject = ''
			this.clearKnowledge()
			examSubject(val).then(res => {
				this.subjectArr = JSON.parse(
					JSON.stringify(res.data.data)
						.replace(/id/g, 'value')
						.replace(/subject_name/g, 'label')
						.replace(/child/g, 'children')
				)
			})
		},
		// 获取关联知识点数据
		subjectidChange(val) {
			this.clearKnowledge()
			this.abilitysArr = []
			abilityList(val[0]).then(res => {
				this.abilitysArr = JSON.parse(
					JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label').replace(/child/g, 'children')
				)
			})
		},
		// 关联知识点删除题目按钮
		deleteKnowledge(val, index) {
			let str = val.only
			if (this.FormData.wiki.length > 1) {
				this.FormData.wiki.splice(index, 1)
				this.min = this.FormData.wiki.length
				this.titleArr = []
				this.FormData.wiki.forEach((item, index_) => {
					if (item.serialnum != '') {
						this.titleArr.push({ value: item.only, label: item.serialnum })
					}
					if (item.set_title == str) {
						item.set_title = ''
					}
				})
			} else {
				this.$message.error('至少保留一个关联知识点')
			}
		},
		// 关联知识点新增题目按钮
		addKnowledge() {
			if (this.FormData.wiki.length + 1 > this.FormData.repnum) {
				this.$message.warning('关联知识点数量不能大于题目数量')
			} else {
				this.FormData.wiki.push({
					serialnum: '', //题号
					score: '', //	分数
					abilitys: '', //	知识点
					set_title: '', //	关联题
					group_id: ++this.num, //组号
					only: ++this.num
				})
				this.min = this.FormData.wiki.length
			}
		},
		serialnumBlur(item, index) {
			if (item.serialnum != '') {
				let tag = 0
				this.FormData.wiki.forEach(i => {
					if (i.serialnum != '') {
						if (i.serialnum == item.serialnum) {
							tag++
							if (tag > 1) {
								item.serialnum = ''
								this.$message.error('题号不能重复')
							}
						}
					}
				})
				this.titleArr = []
				this.FormData.wiki.forEach((item, n) => {
					if (item.serialnum != '') this.titleArr.push({ value: item.only, label: item.serialnum })
				})
			} else {
				item.serialnum = this.hosting
				this.$message.error('题号不能为空')
				this.titleArr = []
				this.FormData.wiki.forEach((item, n) => {
					if (item.serialnum != '') this.titleArr.push({ value: item.only, label: item.serialnum })
				})
			}
		},
		settitleChange(val, index) {
			this.FormData.wiki[index].group_id = val.only
		},
		serialnumFocused(item, index) {
			this.hosting = item.serialnum
		},
		// 取消按钮
		cancel() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.back()
			})
		},
		// 保存按钮
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					for (let index = 0; index < this.FormData.wiki.length; index++) {
						if (this.FormData.wiki[index].serialnum == '') {
							this.$message.error('题号不能为空')
							return
						}
						if (this.FormData.wiki[index].abilitys == '') {
							this.$message.error('知识点不能为空')
							return
						}
						if (this.FormData.wiki[index].abilitys.length > 20) {
							this.$message.error('知识点范围太多请重新选择')
							return
						}
					}
					putrepository(this.$route.query.id, this.FormData).then(res => {
						if (res.data.error.errorCode === 0) {
							this.$message({
								type: 'success',
								message: '编辑成功!'
							})
							this.$router.back()
						} else {
							this.$message({
								type: 'error',
								message: res.data.error.errorMsg
							})
						}
					})
				}
			})
		},
		// 清空考点知识库数据
		clearKnowledge() {
			this.FormData.wiki.forEach(item => {
				item.abilitys = ''
			})
		},
		initialize() {
			repository(this.$route.query.id).then(res => {
				res.data.data.exam_id = res.data.data.exam_id.toString()
				// res.data.data.wiki.forEach(item => {
				// 	item.abilitys = JSON.parse(item.abilitys)
				// })
				this.FormData = res.data.data
				this.min = this.FormData.repnum
			})
		}
	},
	mounted() {},
	created() {
		// 获取考试数据
		getExam().then(res => {
			this.examArr = JSON.parse(
				JSON.stringify(res.data.data)
					.replace(/exam_id/g, 'value')
					.replace(/exam_name/g, 'label')
			)
			// this.examArr = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'))
		})
		// 获取数据详情
		repository(this.$route.query.id).then(res => {
			res.data.data.wiki.forEach(item => {
				this.titleArr.push({ value: item.only, label: item.serialnum })
			})
			// 获取科目数据回显展示
			examSubject(res.data.data.exam_id).then(res => {
				this.subjectArr = JSON.parse(
					JSON.stringify(res.data.data)
						.replace(/id/g, 'value')
						.replace(/subject_name/g, 'label')
						.replace(/child/g, 'children')
				)
			})
			abilityList(res.data.data.subject[0]).then(res => {
				this.abilitysArr = JSON.parse(
					JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label').replace(/child/g, 'children')
				)
				this.loading = false
			})
		})
		this.initialize()
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item{
  margin-bottom: 10rem !important;
}
.knowledge {
	margin-top: 15rem;
	display: flex;
	justify-content: center;
	align-items: center;
	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		background-color: #f4f4f4;
	}
	.add_knowledge {
		display: flex;
		padding-left: 40rem;
	}
}
</style>
